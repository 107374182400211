import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
gsap.registerPlugin(SplitText)

import Site from 'stores/Site'
import Component from 'classes/Component'

export default class Header extends Component {
    constructor() {
        super()

        Site.navActive = false
        Site.ctaActive = true

        this.el = Site.header
        this.main = this.el.querySelector('.header-main')
        this.bg = this.el.querySelector('.header-main-bg')
        this.height = this.bg.offsetHeight
        this.nav = this.el.querySelector('.header-nav')
        this.menu = this.nav.querySelector('.nav')
        this.menuLinks = this.menu.querySelectorAll('a')
        this.tabs = this.el.querySelectorAll('.header-tab')
        this.buttons = this.el.querySelectorAll('.header-buttons .btn')
        this.socialButtons = this.el.querySelectorAll('.social-btn')
        this.isLoading = false
        this.menuItems = []
        this.topLevelMenuItems = []
        this.clickHandlers = []
    }

    init() {
        super.init()

        this.handleScroll()
        this.initDesktopNav()
        this.initMobileNav()
        this.initMobileNavToggle()
    }

    initDesktopNav() {
        if (Site.isMobile) return

        this.menuLinks.forEach(link => {
            const text = link.querySelector('.text')

            const split = new SplitText(text, {
                type: 'lines',
                linesClass: 'line-inner'
            })

            const splitOuter = new SplitText(text, {
                type: 'lines',
                linesClass: 'line'
            })
        })
    }

    initMobileNav() {
        if (!Site.isMobile) return

        gsap.set(this.nav, {
            pointerEvents: 'none',
            autoAlpha: 0,
            visibility: 'hidden'
        })

        this.tabs.forEach(tab => {
            const text = tab.querySelector('.header-tab-text')

            new SplitText(text, {
                type: 'lines',
                linesClass: 'line'
            })
        })

        this.buttons.forEach(btn => {
            gsap.set(btn, {
                autoAlpha: 0
            })
        })

        this.socialButtons.forEach(btn => {
            gsap.set(btn, {
                autoAlpha: 0
            })
        })

        this.menuLinks.forEach(link => {
            const item = link.parentElement
            const text = link.querySelector('.text')
            const toggle = link.querySelector('.toggle')
            const depth = parseInt(text.getAttribute('data-depth'))
            const split = new SplitText(text, {
                type: 'lines',
                linesClass: 'line-inner'
            })

            const splitOuter = new SplitText(text, {
                type: 'lines',
                linesClass: 'line'
            })

            const obj = {
                item,
                link,
                text,
                toggle,
                depth,
                split,
                line: split.lines[0],
                hasChildren: item.classList.contains('menu-item-has-children'),
                subMenuWrapper: item.querySelector(':scope > .sub-menu-wrapper'),
            }

            const handler = this.onMobileNavItemClick(obj)
            link.addEventListener('click', handler)

            this.clickHandlers.push({ link, handler })
            this.menuItems.push(obj)

            if (depth === 0) {
                this.topLevelMenuItems.push(obj)
            }

            if (depth === 1) {
                const border = document.createElement('span')
                border.classList.add('border')
                item.appendChild(border)

                gsap.set(border, {
                    scaleX: 0,
                    transformOrigin: 'left'
                })
            }

            if (obj.hasChildren) {
                gsap.set(obj.toggle, {
                    scale: 0,
                    rotate: 180
                })
            }

            gsap.set(splitOuter.lines[0], {
                overflow: 'hidden'
            })

            gsap.set(obj.line, {
                yPercent: 150,
                rotate: 10
            })
        })
    }

    handleScroll() {
        let direction = -1

        Site.scroll.on('scroll', (scroll) => {
            if (Site.html.classList.contains('scrolled')) {
                if (direction !== scroll.direction) {
                    if (scroll.direction === -1) {
                        Site.html.classList.remove('scrolled-down')
                        Site.html.classList.add('scrolled-up')
                    } else if (scroll.direction === 1) {
                        Site.html.classList.remove('scrolled-up')
                        Site.html.classList.add('scrolled-down')
                    }

                    direction = scroll.direction
                }
            } else {
                Site.html.classList.remove('scrolled-up')
                Site.html.classList.remove('scrolled-down')
            }
        })
    }

    onMobileNavItemClick(obj) {
        const toggleParentItemOff = (obj) => this.toggleParentItemOff(obj)
        const toggleParentItemOn = (obj) => this.toggleParentItemOn(obj)

        return function(e) {
            if (e.target.classList.contains('toggle') || obj.link.getAttribute('href') === '#') {
                e.preventDefault()
                e.stopPropagation()
            }

            if (obj.hasChildren) {
                if (obj.item.classList.contains('active')) {

                    toggleParentItemOff(obj)

                    obj.item.querySelectorAll('.menu-item.active').forEach(childObj => {
                        toggleParentItemOff(childObj)
                    })
                } else {
                    toggleParentItemOn(obj)
                }
            }
        }
    }


    toggleParentItemOn(obj) {
        gsap.set(obj.link, {
            pointerEvents: 'none'
        })

        gsap.set(obj.link, {
            pointerEvents: 'auto',
            delay: .4
        })

        const lines = obj.subMenuWrapper.querySelectorAll(':scope > .sub-menu-inner > .sub-menu > .menu-item > a .line-inner')
        const borders = obj.subMenuWrapper.querySelectorAll(':scope > .sub-menu-inner > .sub-menu > .menu-item > .border')

        obj.item.classList.add('active')

        gsap.to(obj.subMenuWrapper, {
            height: 'auto',
            duration: .4,
            immediateRender: false,
            ease: 'Power2.easeInOut'
        })

        if (obj.depth === 0) {
            gsap.to(obj.toggle, {
                rotate: 90,
                ease: 'Power2.easeOut',
                overwrite: 'auto'
            })
        } else {
            gsap.to(obj.toggle, {
                rotate: 180,
                ease: 'Power2.easeOut',
                overwrite: 'auto'
            })
        }

        lines.forEach((line, i) => {
            gsap.fromTo(line, {
                yPercent: 150,
                rotate: 10,
            }, {
                yPercent: 0,
                rotate: 0,
                ease: 'Power4.easeOut',
                duration: 1,
                delay: i * .05,
                overwrite: 'auto'
            })
        })

        if (borders.length !== 0) {
            borders.forEach((border, i) => {
                gsap.fromTo(border, {
                    scaleX: 0,
                }, {
                    scaleX: 1,
                    ease: 'Power4.easeOut',
                    duration: .8,
                    delay: .2 + i * .05
                })
            })
        }

        if (obj.hasChildren) {
            const toggles = obj.subMenuWrapper.querySelectorAll(':scope > .sub-menu-inner > .sub-menu > .menu-item > a .toggle')

            toggles.forEach((toggle, i) => {
                gsap.fromTo(toggle, {
                    scale: 0,
                    rotate: 180,
                }, {
                    scale: 1,
                    rotate: 0,
                    ease: 'Power4.easeOut',
                    duration: .6,
                    delay: .1 + i * .1
                })
            })
        }
    }

    toggleParentItemOff(obj) {
        gsap.set(obj.link, {
            pointerEvents: 'none'
        })

        gsap.set(obj.link, {
            pointerEvents: 'auto',
            delay: .4
        })

        const lines = obj.subMenuWrapper.querySelectorAll(':scope > .sub-menu-inner > .sub-menu > .menu-item > a .line-inner')
        const borders = obj.subMenuWrapper.querySelectorAll(':scope > .sub-menu-inner > .sub-menu > .menu-item > .border')

        obj.item.classList.remove('active')

        gsap.to(obj.subMenuWrapper, {
            height: 0,
            duration: .8,
            immediateRender: false,
            ease: 'Power4.easeOut',
            overwrite: 'auto'
        })

        gsap.to(obj.toggle, {
            rotate: 0,
            ease: 'Power2.easeOut',
            overwrite: 'auto'
        })

        lines.forEach((line, i) => {
            gsap.to(line, {
                yPercent: 120,
                rotate: 2,
                ease: 'Power4.easeOut',
                duration: 1.6,
                delay: i * .05,
                overwrite: 'auto'
            })
        })

        if (borders.length !== 0) {
            borders.forEach((border, i) => {
                gsap.to(border, {
                    scaleX: 0,
                    ease: 'Power4.easeOut',
                    duration: 1,
                    delay: i * .05
                })
            })
        }

        if (obj.hasChildren) {
            const toggles = obj.subMenuWrapper.querySelectorAll(':scope > .sub-menu-inner > .sub-menu > .menu-item > a .toggle')

            toggles.forEach((toggle, i) => {
                gsap.to(toggle, {
                    scale: 0,
                    rotate: 180,
                    ease: 'Power4.easeOut',
                    duration: .6,
                    delay: .1 + i * .1
                })
            })
        }
    }

    destroyMobileNavItems() {
        if (!Site.isMobile) return

        this.clickHandlers.forEach(clickHandler => {
            clickHandler.link.removeEventListener('click', clickHandler.handler)
        })
    }

    initMobileNavToggle() {
        if (!Site.isMobile) return

        this.toggle = this.el.querySelector('.header-nav-toggle')
        this.toggle.addEventListener('click', this.toggleNav.bind(this))
    }

    destroyMobileNavToggle() {
        if (!Site.isMobile) return
        this.toggle.removeEventListener('click', this.toggleNav.bind(this))
    }

    toggleNav() {
        if (this.isLoading) return

        this.isLoading = true
        Site.navActive = !Site.navActive

        if (Site.navActive) {
            this.showNav()
        } else {
            this.hideNav()
        }
    }

    killTls() {
        if (this.navInTl) {
            this.navInTl.kill()
        }

        if (this.navOutTl) {
            this.navOutTl.kill()
        }
    }

    showNav() {
        this.killTls()

        Site.scroll.stop()
        Site.html.classList.add('nav-active')

        this.navInTl = gsap.timeline({
            defaults: {
                overwrite: 'auto'
            }
        })

        this.navInTl.set(this.nav, {
            pointerEvents: 'auto',
            autoAlpha: 1,
            visibility: 'visible',
        })

        this.navInTl.to(this.bg, {
            height: '100%',
            ease: 'Power4.easeInOut',
            duration: .8,
        }, 0)

        this.topLevelMenuItems.forEach((obj, i) => {
            this.navInTl.fromTo(obj.line, {
                yPercent: 150,
                rotate: 10,
            }, {
                yPercent: 0,
                rotate: 0,
                ease: 'Power4.easeOut',
                duration: 1,
            }, .2 + i * .1)

            if (obj.toggle) {
                this.navInTl.fromTo(obj.toggle, {
                    scale: 0,
                    rotate: 180,
                }, {
                    scale: 1,
                    rotate: 0,
                    ease: 'Power4.easeOut',
                    duration: .6,
                }, .3 + i * .1)
            }
        })

        this.buttons.forEach((btn, i) => {
            this.navInTl.fromTo(btn, {
                autoAlpha: 0,
            },{
                autoAlpha: 1,
                ease: 'Power2.easeInOut',
                duration: .6,
            }, .4 + i * .03)
        })

        this.socialButtons.forEach((btn, i) => {
            this.navInTl.fromTo(btn, {
                autoAlpha: 0,
            },{
                autoAlpha: 1,
                ease: 'Power2.easeInOut',
                duration: .6,
            }, .4 + i * .03)
        })

        this.navInTl.add(()=> {
            this.isLoading = false
        }, .6)
    }

    hideNav() {
        this.killTls()

        Site.scroll.start()
        Site.html.classList.remove('nav-active')

        this.navOutTl = gsap.timeline({
            onComplete: () => {
                this.isLoading = false
            },
            defaults: {
                overwrite: 'auto'
            }
        })

        this.menu.querySelectorAll('.menu-item.active').forEach(item => {
            const subMenuWrapper = item.querySelector(':scope > .sub-menu-wrapper')

            item.classList.remove('active')

            gsap.to(subMenuWrapper, {
                height: 0,
                duration: .4,
                immediateRender: false,
                ease: 'Power2.easeInOut'
            })
        })

        this.buttons.forEach((btn, i) => {
            this.navOutTl.to(btn, {
                autoAlpha: 0,
                duration: .3,
            },  0)
        })

        this.socialButtons.forEach((btn, i) => {
            this.navOutTl.to(btn, {
                autoAlpha: 0,
                duration: .3,
            },  0)
        })

        this.topLevelMenuItems.forEach((obj, i) => {
            this.navOutTl.to(obj.line, {
                yPercent: -120,
                rotate: -5,
                ease: 'Power2.easeInOut',
                duration: .6,
            }, i * .05)

            if (obj.toggle) {
                this.navOutTl.to(obj.toggle, {
                    scale: 0,
                    rotate: 180,
                    ease: 'Power4.easeOut',
                    duration: .6,
                }, .1 + i * .05)
            }
        })

        this.navOutTl.to(this.bg, {
            height: this.height,
            ease: 'Power4.easeInOut',
            duration: .8,
        }, .2)

        this.navOutTl.set(this.nav, {
            pointerEvents: 'none',
            autoAlpha: 0,
            visibility: 'hidden',
        })

        this.navOutTl.add(()=> {
            this.isLoading = false
        }, .6)
    }

    show() {
        this.showAnim = gsap.fromTo(Site.header, {
            yPercent: -100
        },{
            yPercent: 0,
            duration: 1.6,
            rotate: '0.001deg',
            ease: 'Power4.easeOut',
            overwrite: 'auto',
            onComplete: () => {
                gsap.set(Site.header, {
                    clearProps: 'all'
                })
            }
        })
    }

    hide() {
        if (Site.isMobile) return

        this.hideAnim = gsap.to(Site.header, {
            yPercent: -100,
            duration: 1,
            rotate: '0.001deg',
            ease: 'Power4.easeInOut',
            overwrite: 'auto',
            onComplete: () => {
                gsap.set(Site.header, {
                    clearProps: 'all'
                })
            }
        })
    }

    pageLoaded() {
        super.pageLoaded()
    }

    resize() {
        super.resize()

        this.height = this.bg.offsetHeight
    }

    update() {
        super.update()
    }

    destroy() {
        super.destroy()

        this.destroyMobileNavToggle()
        this.destroyMobileNavItems()
    }
}