export default class Component {
    constructor() {

    }

    init() {

    }

    pageLoaded() {

    }

    update() {

    }

    resize() {

    }

    destroy() {

    }
}