import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
gsap.registerPlugin(SplitText)
import {splitLine} from 'utils/splitLine'

import Site from 'stores/Site'
import Component from 'classes/Component'

export default class Footer extends Component {
    constructor() {
        super()

        this.el = Site.footer
    }

    init() {
        super.init()

        if (!this.el) return

        this.initSocialButtons()
        this.initMobileNav()
        this.adjustMobileLegalText()
        this.initScrolls()
    }

    initSocialButtons() {
        this.buttons = this.el.querySelectorAll('.social-btn')

        this.buttons.forEach(btn => {
            const text = btn.querySelector('.social-btn-text')

            splitLine(text)
        })
    }

    initScrolls() {
        const sections = []
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: this.el,
                start: 'top bottom',
                end: 'bottom bottom',
                scrub: 1.1
            }
        })

        sections.push(this.el.querySelector('.footer-top'))
        sections.push(this.el.querySelector('.footer-bottom'))
        sections.push(this.el.querySelector('.footer-copyright'))

        sections.forEach(section => {
            tl.fromTo(section, {
                yPercent: 20
            },{
                yPercent: 0
            }, 0)
        })
    }

    adjustMobileLegalText() {
        if (!Site.isMobile) return

        this.legalNavTexts = this.el.querySelectorAll('.legal-nav a')

        this.legalNavTexts.forEach(text => {
            if (text.textContent === 'Terms and Conditions') {
                text.textContent = 'T\'s & C\'s'
            }
        })
    }

    initMobileNav() {
        if (!Site.isMobile) return

        this.menuEls = this.el.querySelectorAll('.footer-menu')
        this.menus = []
        this.clickHandlers = []

        this.menuEls.forEach(menuEl => {
            const item = menuEl
            const heading = menuEl.querySelector('.footer-menu-heading')
            const height = heading.offsetHeight
            const toggle = heading.querySelector('.toggle')
            let texts = item.querySelectorAll('.menu .text')
            const contactTitles = item.querySelectorAll('.contact-title')
            const contactLinks = item.querySelectorAll('.contact-link')
            texts = [...texts, ...contactTitles, ...contactLinks]
            const lines = []

            texts.forEach(text => {
                const split = new SplitText(text, {
                    type: 'lines',
                    linesClass: 'line-inner'
                })

                const splitOuter = new SplitText(text, {
                    type: 'lines',
                    linesClass: 'line'
                })

                gsap.set(split.lines[0], {
                    yPercent: 150,
                    rotate: 10
                })

                gsap.set(splitOuter.lines[0], {
                    overflow: 'hidden'
                })

                lines.push(split.lines[0])
            })

            const obj = {
                item,
                heading,
                toggle,
                lines,
                height
            }

            const handler = this.onMobileNavHeadingClick(obj)
            heading.addEventListener('click', handler)

            this.clickHandlers.push({ heading, handler })
            this.menus.push(obj)

            gsap.set(item, {
                overflow: 'hidden',
                height: obj.height
            })
        })
    }

    destroyMobileNav() {
        if (!Site.isMobile) return

        this.clickHandlers.forEach(clickHandler => {
            clickHandler.heading.removeEventListener('click', clickHandler.handler)
        })
    }

    onMobileNavHeadingClick(obj) {
        return function(e) {
            gsap.set(obj.heading, {
                pointerEvents: 'none'
            })

            gsap.set(obj.heading, {
                pointerEvents: 'auto',
                delay: .4
            })

            if (obj.item.classList.contains('active')) {
                obj.item.classList.remove('active')

                gsap.to(obj.item, {
                    height: obj.height,
                    duration: .8,
                    immediateRender: false,
                    ease: 'Power4.easeOut',
                    overwrite: 'auto'
                })

                gsap.to(obj.toggle, {
                    rotate: 0,
                    ease: 'Power2.easeOut',
                    overwrite: 'auto'
                })

                obj.lines.forEach((line, i) => {
                    gsap.to(line, {
                        yPercent: 120,
                        rotate: 2,
                        ease: 'Power4.easeOut',
                        duration: 1.6,
                        delay: i * .05
                    })
                })
            } else {
                obj.item.classList.add('active')

                gsap.to(obj.item, {
                    height: 'auto',
                    duration: .4,
                    immediateRender: false,
                    ease: 'Power2.easeInOut',
                    overwrite: 'auto'
                })

                gsap.to(obj.toggle, {
                    rotate: 180,
                    ease: 'Power2.easeOut',
                    overwrite: 'auto'
                })

                obj.lines.forEach((line, i) => {
                    gsap.fromTo(line, {
                        yPercent: 150,
                        rotate: 10,
                    }, {
                        yPercent: 0,
                        rotate: 0,
                        ease: 'Power4.easeOut',
                        duration: 1,
                        delay: i * .05
                    })
                })
            }
        }
    }

    pageLoaded() {
        super.pageLoaded()
    }

    resize() {
        super.resize()
    }

    update() {
        super.update()
    }

    destroy() {
        super.destroy()

        if (!this.el) return

        this.destroyMobileNav()
    }
}